define(['app', 'siteObj', 'accessibleModalHelper'], function (app, siteObj, accessibleModalHelper) {
  
  class epopup {
    constructor(content, styleClass, central, clone, closeText, closeButtonCallback) {
      this.ariaLabel = closeText || (siteObj.props && siteObj.props.accessibility.close || 'Close');
  
      this.central = central;
      this.type = typeof (content);
      this.className = styleClass;
      this.closeButtonCallback = closeButtonCallback;
      this.closeSVG = `<svg class="epopupCloseSVG" width="22" height="22" viewBox="-3 -4 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.414 7l5.293-5.293c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-5.293 5.293-5.293-5.293c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l5.293 5.293-5.293 5.293c-.391.391-.391 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293l5.293-5.293 5.293 5.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414l-5.293-5.293"/>
                       </svg>`;
      
      this.closeButton = `<button type='button' id="epopup-close-button" class='epopupClose js-popup-close-btn epopupClose--defaultStyle' aria-label='${this.ariaLabel}'>${this.closeSVG}</button>`;
  
      this.popupDiv = `<div id='popup' data-component='ePopup' data-componentLoad='helper' class='popup-overlay popupOverlay' role='dialog' tabindex="-1">
                            <div class='js-popup-container popupContainer' role='dialog' tabindex="-1" aria-labelledby='popup-title'>
                                <div class='js-popup-content popupContent'></div>
                            </div>
                        </div>`;
      
      this.existing = !!(document.querySelector('[data-component="ePopup"]'));
      if (!this.existing) this.createNew();
      this.elements = this.getElements();
      this.screen = this.getScreenSize();
      this.content = this.getContent(content, clone);
      
      this.insertContent();
      this.show();
      this.style();
      this.centralisation();
      this.addCloseHandler();
      
      const contentElement = this.getElements().content;
  
      this.accessibleModalHelper = new accessibleModalHelper(contentElement, () => this.closeHandler(this), contentElement.querySelector('[data-popup-title]'));
      window.addEventListener('resize', this.centralisation.bind(this));
    }
    
    reloadAccessibilityHelper(startElement) {
      this.accessibleModalHelper.load(startElement);
    }
    
    /**
     * Create new ePopup
     */
    createNew() {
      document.body.insertAdjacentHTML('beforeend', this.popupDiv);
    }
    
    /**
     * Get popup content
     * @param content
     * @param clone
     * @returns {*}
     */
    getContent(content, clone) {
      if (this.type !== 'string' && clone) return content.cloneNode(true);
      else return content;
    }
    
    /**
     * Get ePopup elements
     * @returns {{html: Element, popup: Element, content: Element, container: Element}}
     */
    getElements() {
      return {
        html: document.documentElement,
        popup: document.querySelector('[data-component="ePopup"]'),
        content: document.querySelector('.js-popup-content'),
        container: document.querySelector('.js-popup-container'),
      };
    }
    
    /**
     * Get device width and height
     * @returns {{width: number, height: number}}
     */
    getScreenSize() {
      return {
        width: this.elements.html.clientWidth,
        height: this.elements.html.clientHeight,
      };
    }
    
    /**
     * Insert ePopup content
     */
    insertContent() {
      if (this.existing) this.clearContent();
      
      if (this.className) this.elements.popup.classList.add(this.className);
      
      if (this.content) {
        if (this.type === 'string')
          this.elements.content.innerHTML = this.content;
        else this.elements.content.appendChild(this.content);
      }
  
      // Add Close Button
      this.elements.content.insertAdjacentHTML('beforeend', this.closeButton);
      const elementWithClassDefinition = this.elements.content.querySelector('[data-close-button-class]');
      if (elementWithClassDefinition) {
        const newCloseButtonClass = elementWithClassDefinition.dataset.closeButtonClass;
        const closeButtonElement = this.elements.content.querySelector('#epopup-close-button');
        closeButtonElement.classList.remove('epopupClose--defaultStyle');
        closeButtonElement.classList.add(newCloseButtonClass);
      }
  
      // Add Title Id for aria-labelledBy
      const popupTitle = this.elements.content.querySelector('[data-popup-title]');
  
      if (popupTitle) {
        popupTitle.setAttribute('id', 'popup-title');
      }
    }
  
    /**
     * Clear ePopup content
     */
    clearContent() {
      this.elements.popup.className = 'popup-overlay popupOverlay';
      this.elements.content.innerHTML = '';
    }
    
    /**
     * Show ePopup
     */
    show() {
      app.element.addClass('active', this.elements.popup);
      if (this.className !== 'ugcCarousel_popupOverlay')
        app.element.addClass('js-popup-lock', this.elements.html);
    }
    
    /**
     * Style for IE with scroll bar
     */
    style() {
      const newScreenWidth = this.elements.html.clientWidth;
      const scrollBarWidth = this.screen.width - newScreenWidth;
      
      this.elements.html.style.marginRight = `${scrollBarWidth} px`;
      this.elements.container.style.top = '';
    }
    
    /**
     * Centralise ePopup
     */
    centralisation() {
      let screenHeight = this.elements.html.clientHeight;
      let popupHeight = this.elements.container.clientHeight;
      let centerTop = ((1 - (popupHeight / screenHeight)) / 2) * 100;
  
      if (centerTop > 0) {
        this.elements.container.style.top = centerTop + '%';
      }
    }
  
    /**
     * ePopup close button event handler
     */
    addCloseHandler() {
      const SELF = this;
      document.querySelector('.popupOverlay').addEventListener('click', function closePopupListener(e) {
        const overlay = document.querySelector('.popupOverlay');
        const button = document.querySelector('#epopup-close-button');
        if (e.target === button || e.target === overlay) {
          document.querySelector('.popupOverlay').removeEventListener('click', closePopupListener);
          return SELF.closeHandler(SELF);
        }
      });
    }
  
    closeHandler(SELF) {
      SELF.close();
      app.publish('epopup/default-close');
    }
  
    /**
     * Close the popup
     */
    close() {
      app.element.removeClass('active', this.elements.popup);
      app.element.removeClass('js-popup-lock', this.elements.html);
      app.element.removeAttribute('style', this.elements.html);
    
      window.removeEventListener('resize', this.centralisation);
    
      this.accessibleModalHelper && this.accessibleModalHelper.close();
      this.closeButtonCallback && this.closeButtonCallback();
    }
  
  }
  
  return epopup;
});
